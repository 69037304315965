<template>
  <div>
    <b-card title="Settle Invoice" class="settle-invoice-card">
      <validation-observer ref="refSelectPaymentMethodForm">
        <h5>
          Select Your Preferred Payment Method for Invoice
          <span class="invoice-number">#{{ paymentInvoiceId }}</span>
        </h5>
        <b-form-group label="Select Payment Method" label-for="selectPaymentMethod">
          <validation-provider
            #default="{ errors }"
            name="Payment Method"
            rules="required"
          >
              <b-form-radio
                v-model="selectedPaymentMethod"
                plain
                name="some-radios3"
                value="M-Pesa"
              >
                M-pesa
              </b-form-radio>
              <b-form-radio
                v-model="selectedPaymentMethod"
                plain
                name="some-radios2"
                value="Debit/Credit Card"
              >
                Debit/Credit Card
              </b-form-radio>
              <b-form-radio
                v-model="selectedPaymentMethod"
                plain
                name="some-radios2"
                value="Cheque"
              >
                Cheque
              </b-form-radio>
            <small class="text-danger" v-if="errors[0]">Please select one payment method</small>
          </validation-provider>
        </b-form-group>

        <b-button
          variant="outline-secondary"
          :to="{ name: 'invoice-view', params: { id: paymentInvoiceId } }"
        >
          Cancel
        </b-button>

        <b-button
          class="float-right"
          variant="primary"
          :disabled="isLoading"
          @click="selectPaymentMethod"
        >
          {{ !isLoading ? "Pay" : "Processing..." }}
          <b-spinner v-if="isLoading" small />
        </b-button>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BFormInput,
  BFormGroup,
  BSpinner,
  BFormRadio,
} from "bootstrap-vue";
import invoiceStoreModule from "./invoiceStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import useInvoiceList from "./useInvoiceList";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BSpinner,
    BFormRadio,

    ValidationProvider,
    ValidationObserver,
    required,
  },
  setup() {
    const invoiceData = ref(null);
    const paymentDetails = ref({});

    const INVOICE_APP_STORE_MODULE_NAME = "invoice";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("invoice/fetchInvoice", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        invoiceData.value = response.data.data;
        // paymentDetails.value = response.data.paymentDetails;
        paymentInvoiceId.value = invoiceData.value.invoice_id;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          invoiceData.value = undefined;
        }
      });

    const {
      isLoading,

      paymentInvoiceId,

      refSelectPaymentMethodForm,

      selectedPaymentMethod,
      selectPaymentMethod,
    } = useInvoiceList();

    return {
      isLoading,

      invoiceData,
      paymentDetails,

      paymentInvoiceId,

      refSelectPaymentMethodForm,

      selectedPaymentMethod,
      selectPaymentMethod,
    };
  },
};
</script>

<style scoped>
.settle-invoice-card {
  width: 50%
}
@media only screen and (max-width: 992px) {
  .settle-invoice-card {
    width: 100%;
  }
}
</style>
